import React, { useState } from "react"
import type { CountryCode, PhoneNumber } from "libphonenumber-js"
import { parsePhoneNumberFromString, getCountries } from "libphonenumber-js"
import { componentStyles } from "@mallardbay/lib-react-components"

import Input from "~components/shared/todo-lib-react-components/input"
import FormControl from "~components/shared/todo-lib-react-components/form-control"
import { TEST_IDS } from "~config/test-ids"
import COPY from "~config/copy-constants"
import Select from "~components/shared/todo-lib-react-components/inputs/select"
import Box from "~components/shared/todo-lib-react-components/box"

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- TODO fix eslint
const DEFAULT_COUNTRY = { label: "US", value: "US" as CountryCode }

export interface Props {
    readonly id?: string
    readonly value: string
    readonly onChange: (value: string) => void
    readonly label?: string
    readonly caption?: string
    readonly placeholder?: string
}

interface CountryOption {
    label: string
    value: CountryCode
}

export default function PhoneNumberInput({
    id,
    value,
    onChange,
    label,
    caption,
    placeholder = COPY.PHONE_PLACEHOLDER,
}: Props) {
    const styles = useStyles()

    const countryOptions = getCountryOptions()
    const parsedPhone = parsePhoneNumberFromString(value)
    const defaultCountry = getDefaultCountryOption({
        countryOptions,
        parsedPhone,
    })

    const [selectedCountry, setSelectedCountry] = useState(defaultCountry.value)

    return (
        <FormControl label={label} caption={caption}>
            <Box style={styles.inputsContainer}>
                <Box style={styles.selectContainer}>
                    <Select
                        testId={TEST_IDS.PHONE_COUNTRY_SELECT}
                        style={{ borderRightRadius: "none" }}
                        value={selectedCountry}
                        onChange={setSelectedCountry}
                        options={countryOptions}
                    />
                </Box>
                <Input
                    testId={TEST_IDS.PHONE_NUMBER_INPUT}
                    id={id}
                    style={{ borderLeftRadius: "none" }}
                    value={parsedPhone?.formatNational() ?? value}
                    onChange={(newValue) =>
                        onInputChange({
                            newValue,
                            selectedCountry,
                            onChange,
                        })
                    }
                    placeholder={placeholder}
                    size="lg"
                />
            </Box>
        </FormControl>
    )
}

function useStyles() {
    return componentStyles({
        inputsContainer: {
            display: "flex",
            gap: "2px",
        },
        selectContainer: {
            width: "110px",
        },
    })
}

function onInputChange({
    newValue,
    selectedCountry,
    onChange,
}: {
    newValue: string
    selectedCountry: CountryCode
    onChange: (value: string) => void
}) {
    const newParsedNumber = parsePhoneNumberFromString(
        newValue,
        selectedCountry
    )

    onChange(newParsedNumber?.number ?? newValue)
}

function getCountryOptions(): CountryOption[] {
    return getCountries().map((country) => ({ label: country, value: country }))
}

function getDefaultCountryOption({
    countryOptions,
    parsedPhone,
}: {
    countryOptions: CountryOption[]
    parsedPhone: PhoneNumber | undefined
}): CountryOption {
    const foundDefault = countryOptions.find(
        (option) => option.value === parsedPhone?.country
    )

    return foundDefault ?? DEFAULT_COUNTRY
}
