import React from "react"
import {
    HorizontalStackSmallGap,
    MediumIconButton,
} from "@mallardbay/lib-react-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight"

import COPY from "~config/copy-constants"

interface Props {
    readonly onLeftClick: () => void
    readonly onRightClick: () => void
    readonly isLeftButtonDisabled: boolean
    readonly isRightButtonDisabled: boolean
    readonly shouldRender?: boolean
}

export default function ResponsiveSliderButtons({
    onLeftClick,
    onRightClick,
    isLeftButtonDisabled,
    isRightButtonDisabled,
    shouldRender = true,
}: Props) {
    const buttonConfigs = getButtonConfigs({
        onLeftClick,
        onRightClick,
        isLeftButtonDisabled,
        isRightButtonDisabled,
    })

    if (!shouldRender) return null

    return (
        <HorizontalStackSmallGap>
            {buttonConfigs.map((config) => (
                <MediumIconButton key={config.ariaLabel} {...config} />
            ))}
        </HorizontalStackSmallGap>
    )
}

function getButtonConfigs({
    onLeftClick,
    onRightClick,
    isLeftButtonDisabled,
    isRightButtonDisabled,
}: Props) {
    return [
        {
            ariaLabel: COPY.PREVIOUS,
            onClick: onLeftClick,
            icon: <FontAwesomeIcon icon={faChevronLeft} />,
            isDisabled: isLeftButtonDisabled,
        },
        {
            ariaLabel: COPY.NEXT,
            onClick: onRightClick,
            icon: <FontAwesomeIcon icon={faChevronRight} />,
            isDisabled: isRightButtonDisabled,
        },
    ]
}
