import { useEffect } from "react"
import { captureException } from "@sentry/react"
import { initAnalytics } from "@mallardbay/lib-shared-helpers"
import { globalHistory } from "@reach/router"
import { v4 as uuid } from "uuid"

import { useUser } from "~config/user-provider"
import { useGetUserTrackingDataQuery } from "~graphql/generated/graphql"
import {
    trackCustomPageView,
    setUserId,
} from "~utils/event-tracking/event-tracking-helpers"

export default function useAnalytics() {
    const { user, isFetching } = useUser()
    const userId = user?.id

    // This ends up being the best location to set the user id for analytics
    // to avoid dependency loops.
    setUserId(userId)

    const { loading: isLoadingProps, data } = useGetUserTrackingDataQuery({
        skip: !userId,
    })
    const userTrackingProps = data?.userTrackingData.props || []
    const isLoading = isFetching || isLoadingProps

    useEffect(() => {
        // TODO allow tracking for not logged in users
        const isReady = !isLoading
        if (!isReady) return

        initAnalytics({
            userId: userId || uuid(),
            userTrackingProps,
            onError: (err) => captureException(err),
        })
    }, [userId, isLoading, userTrackingProps])

    // Custom page view tracking
    useEffect(() => {
        const unsubscribe = globalHistory.listen(({ location }) => {
            trackCustomPageView(location)
        })

        return () => unsubscribe()
    }, [])
}
