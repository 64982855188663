import { useLocation } from "@reach/router"
import type { PropsWithChildren } from "react"
import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"

import type { InputSelectItem } from "~components/shared/todo-lib-react-components/input-select/input-select.types"
import { SEARCH_INPUT_SELECT_ID } from "~config/constants"
import { useResponsive } from "~config/responsive-provider"

interface Props extends PropsWithChildren {
    readonly onSelection: (item: InputSelectItem) => void
}

interface InputSelectContextValue {
    onSelection: (item: InputSelectItem) => void
    isCollapsed: boolean
    setIsCollapsed: (isCollapsed: boolean) => void
    inputSelectContainerRef: React.MutableRefObject<HTMLDivElement | null>
}

const InputSelectContext = createContext<InputSelectContextValue>({
    onSelection: () => {},
    isCollapsed: true,
    setIsCollapsed: () => {},
    inputSelectContainerRef: { current: null },
})

export default function InputSelectProvider({ onSelection, children }: Props) {
    const { isCollapsed, setIsCollapsed, inputSelectContainerRef } =
        useInputSelectCollapse()
    const contextValue = useMemo(
        () => ({
            onSelection,
            isCollapsed,
            setIsCollapsed,
            inputSelectContainerRef,
        }),
        [onSelection, isCollapsed, setIsCollapsed, inputSelectContainerRef]
    )

    return (
        <InputSelectContext.Provider value={contextValue}>
            {children}
        </InputSelectContext.Provider>
    )
}

export function useInputSelect() {
    return useContext(InputSelectContext)
}

function useInputSelectCollapse() {
    const inputSelectContainerRef = useRef<HTMLDivElement | null>(null)
    const [isCollapsed, setIsCollapsed] = useState(true)
    const { windowScroll } = useResponsive()
    const { pathname } = useLocation()

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- TODO fix eslint
            const clickedNode = event.target as Node
            const inputSelectPopovers = document.querySelectorAll(
                `[id="${SEARCH_INPUT_SELECT_ID}"]`
            )

            const isClickedInsideSearch =
                inputSelectContainerRef.current?.contains(clickedNode)

            inputSelectPopovers.forEach((popoverElement) => {
                if (
                    popoverElement.contains(clickedNode) ||
                    isClickedInsideSearch
                ) {
                    return
                } // Do nothing if clicked inside search input popover or header

                // Collapse search input if clicked outside search input popover or header
                setIsCollapsed(true)
            })
        }
        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [inputSelectContainerRef, windowScroll.y])

    // Collapse search input when navigating to new page
    useEffect(() => {
        setIsCollapsed(true)
    }, [pathname])

    return { isCollapsed, setIsCollapsed, inputSelectContainerRef }
}
