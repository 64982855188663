import { useState, useEffect } from "react"
import { makeVar, useReactiveVar } from "@apollo/client"
import { useAuth0 } from "@auth0/auth0-react"

import { useIsUserUsingPasswordLoginQuery } from "~graphql/generated/graphql"
import {
    trackLogin,
    trackLogout,
} from "~utils/event-tracking/event-tracking-helpers"

const accessTokenVar = makeVar<string | null>(null)

export function useAuth() {
    const [error, setError] = useState<Error>()
    const {
        isAuthenticated: hasBeenAuthenticated,
        isLoading: isLoadingAuth0,
        getAccessTokenSilently,
        loginWithRedirect,
        logout,
        user: authUser,
    } = useAuth0()

    const accessToken = useReactiveVar(accessTokenVar)

    useEffect(() => {
        if (!hasBeenAuthenticated || Boolean(accessToken)) return

        void getAndSetAccessToken({ getAccessTokenSilently, setError })
    }, [hasBeenAuthenticated, getAccessTokenSilently])

    const isAuthenticated = hasBeenAuthenticated && Boolean(accessToken)
    const isLoadingToken = !isAuthenticated && hasBeenAuthenticated
    const isLoading = isLoadingToken || isLoadingAuth0

    const wrappedLogin = ({ isSignup }: { isSignup?: boolean } = {}) => {
        trackLogin(authUser?.sub)
        return loginWithRedirect({
            appState: { returnTo: window.location.pathname },
            authorizationParams: { screen_hint: isSignup ? "signup" : "login" },
        })
    }

    const wrappedLogout = async () => {
        await logout({ logoutParams: { returnTo: window.location.origin } })
        accessTokenVar(null)
        trackLogout()
    }

    return {
        accessToken,
        authUser,
        isLoading,
        error,
        isAuthenticated,
        hasBeenAuthenticated,
        loginWithRedirect: wrappedLogin,
        logout: wrappedLogout,
    }
}

export function useAccessToken() {
    const { accessToken } = useAuth()

    return accessToken
}

export function useIsUserUsingPasswordLogin() {
    const { data } = useIsUserUsingPasswordLoginQuery()

    return data?.isUserUsingPasswordLogin ?? false
}

async function getAndSetAccessToken({
    getAccessTokenSilently,
    setError,
}: {
    getAccessTokenSilently: () => Promise<string>
    setError: (error: Error) => void
}) {
    try {
        const accessToken = await getAccessTokenSilently()

        accessTokenVar(accessToken)
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- TODO fix eslint
        setError(error as Error)
    }
}
