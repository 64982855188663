import type { Dispatch, MutableRefObject, SetStateAction } from "react"
import React, { useRef, useState } from "react"
import {
    Box,
    componentStyles,
    HorizontalStackSpaceBetween,
} from "@mallardbay/lib-react-components"
import { throttle, debounce } from "radash"

import PageSectionHeading from "~components/shared/typography/page-section-heading"
import { TEST_IDS } from "~config/test-ids"
import ResponsiveSliderButtons from "~components/shared/responsive-slider/responsive-slider-buttons"
import { useBreakpointValue } from "~components/shared/todo-lib-react-components/hooks/use-breakpoint-value"

// Throttle controls how quickly the buttons allow scrolling to next again after pressing.
// Allowing it to be pressed too quickly resets the scroll animation and causes jumpiness.
const THROTTLE_INTERVAL = 400
// Scroll handling governs how quickly the buttons disable at start/end. This needs to be
// balanced with performance of handling too many scroll events.
const SCROLL_DEBOUNCE_DELAY = 100
const ITEM_COUNT_PER_SIZE = { BASE: 1, SM: 2, MD: 3, LG: 4 }

interface Props {
    readonly items: React.JSX.Element[] | React.ReactNode[]
    readonly title?: string
    readonly onSwipe?: () => void
}

export default function ResponsiveSlider({ items, title, onSwipe }: Props) {
    const styles = useStyles()

    const containerRef = useRef<HTMLDivElement>(null)
    const itemRefs = useRef<(HTMLDivElement | null)[]>([])

    const [isLeftButtonDisabled, setIsLeftButtonDisabled] = useState(true)
    const [isRightButtonDisabled, setIsRightButtonDisabled] = useState(false)

    const handleScroll = useHandleScroll({
        containerRef,
        setIsLeftButtonDisabled,
        setIsRightButtonDisabled,
        onSwipe,
    })
    const handleLeftButtonPress = useHandleNavButtonPress({
        containerRef,
        itemRefs,
        isNextPress: false,
        onSwipe,
    })
    const handleRightButtonPress = useHandleNavButtonPress({
        containerRef,
        itemRefs,
        isNextPress: true,
        onSwipe,
    })
    const shouldRenderNavButtons = useShouldRenderNavButtons({ items })

    return (
        <Box style={styles.root} testId={TEST_IDS.RESONSIVE_SLIDER}>
            <HorizontalStackSpaceBetween style={styles.header}>
                {title && <PageSectionHeading label={title} />}
                <ResponsiveSliderButtons
                    onLeftClick={handleLeftButtonPress}
                    onRightClick={handleRightButtonPress}
                    isLeftButtonDisabled={isLeftButtonDisabled}
                    isRightButtonDisabled={isRightButtonDisabled}
                    shouldRender={shouldRenderNavButtons}
                />
            </HorizontalStackSpaceBetween>
            <Box
                onScroll={handleScroll}
                ref={containerRef}
                style={styles.sliderContainer}
            >
                {items.map((item, idx) => (
                    <Box
                        key={idx}
                        ref={(ref) => (itemRefs.current[idx] = ref)}
                        style={styles.sliderItem}
                    >
                        {item}
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

function useHandleNavButtonPress(args: HandleNavButtonPressArgs) {
    return throttle({ interval: THROTTLE_INTERVAL }, () =>
        handleNavButtonPress(args)
    )
}

function useHandleScroll(args: HandleOnScrollArgs) {
    // Using debounce for scroll handler instead of throttle to ensure that
    // the last call gets processed, instead of just skipping calls.
    return debounce({ delay: SCROLL_DEBOUNCE_DELAY }, () =>
        handleOnScroll(args)
    )
}

function useShouldRenderNavButtons({
    items,
}: {
    items: React.JSX.Element[] | React.ReactNode[]
}) {
    const countBeingRendered = useBreakpointValue({
        base: ITEM_COUNT_PER_SIZE.BASE,
        sm: ITEM_COUNT_PER_SIZE.SM,
        md: ITEM_COUNT_PER_SIZE.MD,
        lg: ITEM_COUNT_PER_SIZE.LG,
    })

    if (!countBeingRendered) return false

    return countBeingRendered < items.length
}

function handleOnScroll({
    containerRef,
    setIsLeftButtonDisabled,
    setIsRightButtonDisabled,
    onSwipe,
}: HandleOnScrollArgs) {
    if (!containerRef.current) return

    const containerScrollLeft = containerRef.current.scrollLeft
    const containerScrollWidth = containerRef.current.scrollWidth
    const containerWidth = containerRef.current.offsetWidth
    const scrollableWidth = containerScrollWidth - containerWidth

    setIsLeftButtonDisabled(containerScrollLeft === 0)
    setIsRightButtonDisabled(containerScrollLeft === scrollableWidth)
    onSwipe?.()
}

function handleNavButtonPress({
    containerRef,
    itemRefs,
    isNextPress,
    onSwipe,
}: HandleNavButtonPressArgs) {
    if (!containerRef.current) return

    const containerScrollLeft = containerRef.current.scrollLeft
    const containerOffsetLeft = containerRef.current.offsetLeft

    // Find the closest item to where we are currently scrolled.
    const closestItemIdx = itemRefs.current.findIndex((item) => {
        if (!item) return false

        return item.offsetLeft >= containerScrollLeft
    })

    // Get the next or previous index and grap the target item to scroll to.
    const indexOffset = isNextPress ? 1 : -1
    const targetItem = itemRefs.current[closestItemIdx + indexOffset]

    if (!targetItem) return

    // Calculate the scroll target inside the container. Need to subtract the
    // container's offset from the target item's offset.
    const scrollToLeft = targetItem.offsetLeft - containerOffsetLeft

    containerRef.current.scrollTo({
        left: scrollToLeft,
        behavior: "smooth",
    })
    onSwipe?.()
}

function useStyles() {
    return componentStyles({
        root: { marginBottom: 10 },
        header: {
            marginBottom: 2,
            paddingX: 2,
        },
        sliderContainer: {
            display: "flex",
            overflowX: "scroll",
            "&::-webkit-scrollbar": { display: "none" },
            scrollSnapType: "x mandatory",
        },
        sliderItem: {
            flexGrow: 0,
            flexShrink: 0,
            width: {
                base: `${100 / ITEM_COUNT_PER_SIZE.BASE}%`,
                sm: `${100 / ITEM_COUNT_PER_SIZE.SM}%`,
                md: `${100 / ITEM_COUNT_PER_SIZE.MD}%`,
                lg: `${100 / ITEM_COUNT_PER_SIZE.LG}%`,
            },
            paddingX: "10px",
            scrollSnapAlign: "start",
        },
    })
}

interface HandleNavButtonPressArgs {
    containerRef: MutableRefObject<HTMLDivElement | null>
    itemRefs: MutableRefObject<(HTMLDivElement | null)[]>
    isNextPress: boolean
    onSwipe?: () => void
}

interface HandleOnScrollArgs {
    containerRef: MutableRefObject<HTMLDivElement | null>
    setIsLeftButtonDisabled: Dispatch<SetStateAction<boolean>>
    setIsRightButtonDisabled: Dispatch<SetStateAction<boolean>>
    onSwipe?: () => void
}
