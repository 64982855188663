import {
    browserTracingIntegration,
    replayIntegration,
    init,
} from "@sentry/gatsby"

import { getVersionNumber } from "~utils/helpers"

init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    autoSessionTracking: true,
    environment: process.env.NODE_ENV,
    enabled: process.env.GATSBY_IS_SENTRY_DISABLED !== "true",
    release: getVersionNumber(),
    integrations: [browserTracingIntegration(), replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})
